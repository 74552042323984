<script>
import { mapActions } from 'vuex'
import { required, sameAs } from 'vuelidate/lib/validators'
import AuthWrapper from '@/views/auth/components/AuthWrapper'
import Action from '@/components/Action'
import Icon from '@/components/Icon'
import InputField from '@/components/InputField'

export default {
  name: 'ChangePassword',

  components: {
    AuthWrapper,
    Action,
    Icon,
    InputField,
  },

  data () {
    return {
      formData: {
        password: null,
        repeatPassword: null,
      },
      loading: false,
    }
  },

  validations: {
    formData: {
      password: {
        required,
      },
      repeatPassword: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
  },

  methods: {
    ...mapActions('auth', ['changePassword']),
    ...mapActions(['setFeedback']),

    clearForm () {
      this.formData = {
        password: null,
        repeatPassword: null,
      }
    },

    handleSubmit () {
      this.$v.$touch()
      if (this.$v.$invalid) { return }
      this.loading = true
      this.changePassword(this.formData).then(() => {
        this.loading = false
        this.setFeedback({ title: 'Senha alterada com sucesso!', message: 'Faça seu login com a nova senha.', type: 'success' })
        this.$router.push({ name: 'auth.login' })
      }).catch(() => {
        this.loading = false
        this.clearForm()
      })
    },
  },
}
</script>

<template>
  <auth-wrapper>
    <h1 :class="$classes['title']">Alterar senha</h1>
    <form @submit.prevent="handleSubmit">
      <input-field
        v-model="formData.password"
        label="Nova senha"
        placeholder="Escreva aqui"
        type="password"
        :validation="$v.formData.password"
      />
      <input-field
        v-model="formData.repeatPassword"
        label="Repetir nova senha"
        placeholder="Escreva aqui"
        type="password"
        :validation="$v.formData.repeatPassword"
      />
      <div :class="$classes['form-actions']">
        <action
          full-width
          variant="contained"
          color="primary"
          type="submit"
          :loading="loading"
          :disabled="loading"
        >
          Salvar
        </action>
      </div>
    </form>
    <action
      variant="flat"
      color="secondary"
      uppercase
      :to="{ name: 'auth.login' }"
    >
      <icon name="long-arrow-alt-left" btnIcon/>
      Voltar
    </action>
  </auth-wrapper>
</template>

<style module>
.title {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.1em;
  margin-bottom: 15px;
  color: rgba(41, 41, 41, 0.8);
}

.form-actions {
  margin-top: 30px;
  margin-bottom: 45px;
}
</style>
